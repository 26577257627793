const homePageConfig = {
  heroSection: {
    sectionTitles: ['PURE PLAY ', 'AI DATA CENTER ', 'DEVELOPMENT.'],
    sectionSubtitle:
      'TigerDC designs and operates purpose-built AI data centers optimized for high-density. With advanced liquid cooling solutions like Direct-to-Chip and Rear Door Heat Exchangers, we efficiently manage racks exceeding 100 kW, delivering the performance and reliability needed for AI workloads.',
  },
  sc1Section: {
    banner: {
      src: 'https://storage.googleapis.com/tiger-dc-web/assets/images/SiteEntrance/xl.webp',
      alt: 'Exterior photograph of SC1 AI data center',
      title: 'SC1 AI DC',
      overlayColor: 'black',
      overlayOpacity: 30,
    },
    features: [
      {
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/cooling-direct.svg',
        iconLabel: 'direct cooling',
        iconBgColor: 'skyBlue',
        iconShrink: 2,
        description:
          'Liquid cooling with Direct-to-Chip and Rear Door Heat Exchangers',
      },
      {
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/build-to-suit.svg',
        iconLabel: 'purpose built',
        iconBgColor: 'skyBlue',
        iconShrink: 2.2,
        description:
          '1.2 PUE, 100 MW purpose-built AI data center in South Carolina',
      },
      {
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/electronics.svg',
        iconLabel: 'electronics',
        iconBgColor: 'skyBlue',
        iconShrink: 2,
        description:
          'Designed and Built to handle 100+ kW racks for high-density AI workloads',
      },
    ],
    moreLink: {
      text: 'More About SC1 →',
      path: '/aboutsc1',
    },
  },
  teamSection: {
    sectionTitle: 'Our Team',
    sectionSubtitle:
      'Our management team possesses extensive expertise in finance, data center engineering, and project management. With decades of experience leading large-scale commercial real estate transactions, executing data center design and construction, and managing operations for top tech companies, we ensure the successful delivery of every data center project.',
    moreLink: {
      text: 'More About Our Team →',
      path: '/team',
    },
  },
  coolingSection: {
    banner: {
      src: 'https://storage.googleapis.com/tiger-dc-web/images/liquidCoolingBanner/xxl.webp',
      alt: 'cooling background graphic',
      title: 'Innovative Liquid \n & Air Cooling',
      overlayColor: 'darkBlue',
      overlayOpacity: 30,
    },
    features: [
      {
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/partnership.svg',
        iconLabel: 'strong partnerships',
        iconBgColor: 'skyBlue',
        iconShrink: 1.8,
        description:
          'Strong Partnerships with Leading Vendors for AI Data Center Design',
      },
      {
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/cooling-indirect.svg',
        iconLabel: 'indirect cooling',
        iconBgColor: 'skyBlue',
        iconShrink: 2.2,
        description:
          'Expertise in High-Density Cooling Solutions, Including Direct-to-Chip and Rear Door Cooling',
      },
      {
        iconSrc:
          'https://storage.googleapis.com/tiger-dc-web/tiger-dc-icons/host.svg',
        iconLabel: 'experience hosting gpus',
        iconBgColor: 'skyBlue',
        iconShrink: 2.3,
        description: 'Proven Experience in Hosting NVIDIA GPUs',
      },
    ],
    moreLink: {
      text: 'More About Cooling →',
      path: '/cooling',
    },
  },
  locationsSection: {
    sectionTitle: 'AI DC LOCATIONS',
    sectionSubtitle: '600 MW capacity secured in the AIDC pipeline.',
    locations: [
      { lat: 34.863082, lng: -82.01883, label: 'SC1' },
      {
        lat: 36.015146,
        lng: -80.23379,
        label: 'NC1',
      },
    ],
  },
};
export default homePageConfig;
