import Styled from '@emotion/styled';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SiteMap from '../components/SiteMap';

import HeroSection from '../components/HomePage/HeroSection';
import SC1Section from '../components/HomePage/SC1Section';
import TeamSection from '../components/HomePage/TeamSection';
import CoolingSection from '../components/HomePage/CoolingSection';
import LocationsSection from '../components/HomePage/LocationsSection';

const HomePageWrapper = Styled.div`
  display: flex;
  flex-direction: column;
`;

const Home = () => (
  <>
    <SEO
      title="Tiger DC  | Purpose-built AI data centers"
      description="TigerDC designs and operates purpose-built AI data centers optimized for high-density. With advanced liquid cooling solutions like Direct-to-Chip and Rear Door Heat Exchangers, we efficiently manage racks exceeding 100 kW, delivering the performance and reliability needed for AI workloads."
      image=""
      canonical="/"
    />
    <HomePageWrapper>
      <Header />
      <HeroSection />
      <SC1Section />
      <TeamSection />
      <CoolingSection />
      <LocationsSection />
      <SiteMap />
      <Footer />
    </HomePageWrapper>
  </>
);

export default Home;
