import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const SEO = ({
  title = 'AI Data Center Development | Tiger DC',
  description = 'TigerDC designs and operates purpose-built AI data centers optimized for high-density. With advanced liquid cooling solutions like Direct-to-Chip and Rear Door Heat Exchangers, we efficiently manage racks exceeding 100 kW, delivering the performance and reliability needed for AI workloads.',
  image = '',
  canonical,
  type = 'website',
  keywords = 'tiger dc, tiger data center, data center, datacenter, ai, machine learning, cloud computing',
}) => {
  const siteUrl = 'https://tigerdc.com';
  const pageUrl = canonical ? `${siteUrl}${canonical}` : siteUrl;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Canonical */}
      <link rel="canonical" href={pageUrl} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  canonical: PropTypes.string,
  type: PropTypes.string,
  keywords: PropTypes.string,
};

export default SEO;
